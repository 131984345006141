export const initUtilities = () => {

    // VIDEO POPUP
    let videoSrc = $(".popup").find("iframe").attr("src");

    $(document).on("click", ".trigger-video", function () {
        const video = $('.overlay-video-mobile').get(0);
        const icon = $('.video-icon');

        if (video.paused) {
            video.play();
            icon.attr("src", "/theme/icons/pause-icon.svg").attr("alt", "Pause");
        } else {
            video.pause();
            icon.attr("src", "/theme/icons/play-icon.svg").attr("alt", "Play");
        }
    });


    $(document).on('keydown', '.trigger-popup', function (e) {
        if (e.key === 'Enter') {
            $(this).trigger('click');
        }
    });

    $(document).on("click", ".popup-close", function () {
        $(".popup").removeClass("flex").addClass("hidden");
        // enable page-video autoplay
        $(".page-video")
            .find("video")
            .each(function () {
                $(this).get(0).play();
            });
        // add scroll when popup is closed
        $("body").removeClass("no-scroll");
        // show play icon
        $(".trigger-popup").show();

        // remove popup iframe
        $(".popup").find("iframe").attr("src", "");
    });


    // FAQ Questions
    $(document).on("click", ".faq-question", function () {
        $(this).toggleClass("open");
        $(this).next().slideToggle(200);
    });

    $(document).on('keydown', '.faq-question', function (e) {
        if (e.key === 'Enter') {
            $(this).trigger('click');
        }
    });

    // if popup is open, close it when clicking outside of iframe-container
    $(document).on("click", ".popup", function (e) {
        if (!$(e.target).closest(".iframe-container").length) {
            $(".popup").removeClass("flex").addClass("hidden");
            // enable page-video autoplay
            $(".page-video")
                .find("video")
                .each(function () {
                    $(this).get(0).play();
                });
            // add scroll when popup is closed
            $("body").removeClass("no-scroll");
            // show play icon
            $(".trigger-popup").show();

            // remove popup iframe
            $(".popup").find("iframe").attr("src", "");
        }
    });

    // if popup is open, close it when pressing ecape key
    $(document).on("keydown", function (e) {
        if (e.key === "Escape") {
            $(".popup").removeClass("flex").addClass("hidden");
            // enable page-video autoplay
            $(".page-video")
                .find("video")
                .each(function () {
                    $(this).get(0).play();
                });
            // add scroll when popup is closed
            $("body").removeClass("no-scroll");
            // show play icon
            $(".trigger-popup").show();

            // remove popup iframe
            $(".popup").find("iframe").attr("src", "");
        }
    });


    //Team Member Popup
    $(document).on("click", ".team-member", function () {
        if (window.matchMedia("(max-width: 1023px)").matches) {

            $(".mobile-text").not($(this).find('.mobile-text')).slideUp(200);
            $('.team-member img').not($(this).find('img')).attr('src', '/theme/icons/plus_icon.svg');

            $(this).find('.mobile-text').slideToggle(200);
            $(this).find('img').attr('src', function (_, src) {
                return src === '/theme/icons/minus_icon.svg' ? '/theme/icons/plus_icon.svg' : '/theme/icons/minus_icon.svg';
            });
        } else {
            let member = $(this).data("member");
            $('.pop-up-container[data-pop-member="' + member + '"]').removeClass("hidden").addClass('flex');
            $("body").addClass("no-scroll");
            $('.wrapper-content').removeClass('relative');
        }
    });

    $(document).on('keydown', '.team-member', function (e) {
        if (e.key === 'Enter') {
            $(this).trigger('click');
        }
    });

    $('.pop-up-overlay, .close').click(function (e) {
        $(this).closest('.pop-up-container').addClass('hidden').removeClass('flex');
        $("body").removeClass("no-scroll");
        $('.wrapper-content').addClass('relative');
    });

    $(document).on("keydown", function (e) {
            if (e.key === "Escape") {
                $('.pop-up-container').addClass('hidden').removeClass('flex');
                $("body").removeClass("no-scroll");
                $('.wrapper-content').addClass('relative');
            }
        }
    );

    // Function to adjust scroll position for anchor links
    function adjustAnchorScroll() {
        const offset = window.matchMedia("(max-width: 1023px)").matches ? 92 : 170;
        const hash = window.location.hash;

        if (hash) {
            requestAnimationFrame(() => {
                const target = document.querySelector(hash);
                if (target) {
                    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - offset;
                    window.scrollTo({
                        top: targetPosition,
                        behavior: 'smooth'
                    });
                }
            });
        }
    }


    window.addEventListener('DOMContentLoaded', adjustAnchorScroll);
    window.addEventListener('load', adjustAnchorScroll);
    window.addEventListener('hashchange', adjustAnchorScroll);
};

export default initUtilities;
