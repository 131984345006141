import AOS from "aos";

export const initAnimations = () => {
  initAOS();
};

function initAOS() {
  AOS.init({
    offset: 100,
    duration: 1000,
    delay: 100,
    once: true,
    disable: () => {
      return window.innerWidth < 768 || window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }
  });
}

export default initAnimations;
