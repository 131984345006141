import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.locale('fr-ca');

const dayMap = {
    'sunday': 0, 'monday': 1, 'tuesday': 2, 'wednesday': 3,
    'thursday': 4, 'friday': 5, 'saturday': 6
};

const formatDayDate = 'dddd [le] D MMMM YYYY';
const formatTime = 'H [h] mm';

export const calendar = () => {
    const generateEvents = (events, items) => {
        return events.reduce((acc, event) => {
            const { canonicalId, date, date2, title, body, uri, featured, image } = event;
            const currentEvent = {
                id: canonicalId,
                startDate: getDate(date),
                endDate: getDate(date2),
                event_title: title,
                title: `<div class="item-wrapper ${featured ? 'wrapper-featured' : 'event-wrapper'}">${getTime(date)} - ${getTime(date2)}<span class="font-semibold"><br></br>${title}</span></div>`,
                tooltip: body,
                popUpTitle: displayPopUpTitle(date, date2),
                classes: ['event'],
                url: uri,
                imageUrl: featured ? image : '',
                live:false,
                type: 'event'
            };

            if (featured) {
                currentEvent.classes.push('event-featured');
            }

            acc.push(currentEvent);
            return acc;
        }, items);
    };

    const generateMasses = (masses, adorations, items, showDate) => {
        const massArray = masses.length > 0 ? getSchedulesAsArray(masses[0].schedules) : [];
        const adorationArray = adorations.length > 0 ? getSchedulesAsArray(adorations[0].adorationSchedules) : [];

        const massItem = createScheduleObject(massArray, updateMassItem);
        const adorationItem = createScheduleObject(adorationArray, updateAdorationItem);

        const currentDate = new Date(showDate.getFullYear(), showDate.getMonth(), 1);

        while (currentDate.getMonth() === showDate.getMonth()) {
            const dayIndex = currentDate.getDay();
            const startDate = new Date(currentDate);
            const endDate = new Date(startDate);
            endDate.setHours(23, 59, 59);

            const event = createEvent(massItem[dayIndex], adorationItem[dayIndex], startDate);
            if (event) items.push(event);

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return items;
    };

    const filterMobileDates = (date, events, masses, adorations) => {
        const mobileDate = dayjs(date);
        const mobileDay = mobileDate.locale('en').format('dddd').toLowerCase();

        let mobileHtml = '';

        const generateHeader = (text) => `
            <div class="rounded text-sm pl-2 mt-8" style="background-color: #FFF3E7;border-left: 3px solid #41484E;">
                <p class="py-2">${text}</p>
            </div>`;

        const processSchedules = (schedules, headerText, formatTime) => {
            let html = '';
            let headerLogged = false;
            let live = false;
            for (const schedule of schedules) {
                if (schedule.fields.day === mobileDay) {
                    if (!headerLogged) {
                        html += generateHeader(headerText);
                        headerLogged = true;
                    }
                    html += formatTime(schedule.fields);
                    if(schedule.fields.liveBroadcast === true){
                        live = true;
                    }
                }

            }
            if(live){
                html += `<a target="_blank" class="btn solid-blue btn-arrow block mt-4" :href="https://www.youtube.com/playlist?list=PLgzgcyL6piaOWIAEI1B2zeSIw6YDDUuk1">
                Regarder la diffusion
              </a>`;
            }
            console.log(schedules)
            return html;
        };

        mobileHtml += events
            .filter(event => dayjs(event.date, "YYYY-MM-DD hh:mm A").isSame(mobileDate, 'day'))
            .map(GenerateMobileHtml)
            .join('');

        const massesArray = masses[0] ? getSchedulesAsArray(masses[0].schedules) : [];
        mobileHtml += processSchedules(
            massesArray,
            "Horaire des messes" + '<br>' +   `<span class='font-semibold'>${mobileDate.format(formatDayDate)}</span>`,
            fields => `<li class="hour-li">${dayjs(fields.startTime, 'HH:mm:ss').format(formatTime)} (${fields.mass_language})</li>`
        );

        const adorationsArray = adorations[0] ? getSchedulesAsArray(adorations[0].adorationSchedules) : [];
        mobileHtml += processSchedules(
            adorationsArray,
            "Horaire de l'adoration" + '<br>' +   `<span class='font-semibold'>${mobileDate.format(formatDayDate)}</span>`,
            fields => `<li class="hour-li">${dayjs(fields.time, 'HH:mm:ss').format(formatTime)} - ${dayjs(fields.time2, 'HH:mm:ss').format(formatTime)}</li>`
        );

        return mobileHtml;
    };

    // Helper functions
    const getSchedulesAsArray = (schedules) => Object.values(schedules);

    const getDate = (timeString) => timeString.split(' ')[0];

    const getTime = (date) => {
        const [timePart] = date.split(' ').slice(1);
        let [hours, minutes] = timePart.split(':').map(Number);

        if (date.includes('p.m.') && hours < 12) {
            hours += 12;
        } else if (date.includes('a.m.') && hours === 12) {
            hours = 0;
        }

        const paddedMinutes = minutes.toString().padStart(2, '0');

        return `${hours} h ${paddedMinutes}`;
    };

    const displayPopUpTitle = (date1, date2) => {
        const parseFormat = 'YYYY-MM-DD hh:mm A';
        const normalizeDate = (date) => date.replace('a.m.', 'AM').replace('p.m.', 'PM');

        const d1 = dayjs(normalizeDate(date1), parseFormat);
        const d2 = dayjs(normalizeDate(date2), parseFormat);

        if (!d1.isValid() || !d2.isValid()) {
            // console.error('Invalid date format. Expected format: YYYY-MM-DD hh:mm A');
            return 'Invalid date';
        }

        if (d1.isSame(d2, 'day')) {
            return `${d1.format(formatDayDate)} ${d1.format(formatTime)} - ${d2.format(formatTime)}`;
        } else {
            return `${d1.format(formatDayDate)} ${d1.format(formatTime)} - ${d2.format(formatDayDate)} ${d2.format(formatTime)}`;
        }
    };

    const displayTimes = (timeString) => {
        const normalizedTimeString = timeString.replace('a.m.', 'AM').replace('p.m.', 'PM');
        let time;

        if (normalizedTimeString.match(/^\d{4}-\d{2}-\d{2} \d{1,2}:\d{2} (AM|PM)$/)) {
            time = dayjs(normalizedTimeString, 'YYYY-MM-DD hh:mm A');
        } else if (normalizedTimeString.match(/^\d{2}:\d{2}:\d{2}$/)) {
            time = dayjs(normalizedTimeString, 'HH:mm:ss');
        } else {
            // console.error('Invalid time format');
            return null;
        }

        return time.format('HH [h] mm');
    };

    const updateMassItem = (massArray, i, massItem) => {
        const { day, startTime, mass_language } = massArray[i].fields;
        const dayIndex = dayMap[day.toLowerCase()];
        if (dayIndex !== undefined) {
            if(massArray[i].fields.liveBroadcast === true){
                massItem[dayIndex].live = true;
            }
            massItem[dayIndex].number += 1;
            const time = displayTimes(startTime.toString());
            massItem[dayIndex].hours += `<li class="hour-li">${time} (${mass_language})</li>`;
        }
    };

    const updateAdorationItem = (adorationArray, i, adorationItem) => {
        const { day, time, time2, mass_language } = adorationArray[i].fields;
        const dayIndex = dayMap[day.toLowerCase()];

        if (dayIndex !== undefined) {
            adorationItem[dayIndex].number += 1;
            const time1 = displayTimes(time.toString());
            const time2Display = displayTimes(time2.toString());
            adorationItem[dayIndex].hours += `\n<li class="hour-li">${time1} à ${time2Display} (${mass_language})</li><br>`;
        }
    };

    const createScheduleObject = (scheduleArray, updateFunction) => {
        const scheduleItem = Array.from({ length: 7 }, () => ({ number: 0, hours: '', live: false }));
        scheduleArray.forEach((_, i) => updateFunction(scheduleArray, i, scheduleItem));
        return scheduleItem;
    };

    const tooltipDisplay = (mass, adoration, date) => {
        let bodyString = '<p class="font-bold text-sm">' + dayjs(date).format(formatDayDate) + '</p>';
        if (mass) {
            bodyString += `<p class="subtitle">Horaire des messes</p>${mass.hours}`;
        }
        if (adoration) {
            bodyString += `<p class="subtitle">Horaire de l'adoration</p>${adoration.hours}`;
        }
        return bodyString;
    };

    const GenerateMobileHtml = (event) => {
        if (!event) return '';
        const { date, date2, title, body } = event;
        return `
            <div class="rounded text-sm pl-2 mt-8" style="background-color: #D9EEEE;border-left: 3px solid #41484E;">
                <p class="py-2">${displayTimes(date)} - ${displayTimes(date2)} <br><span class="font-semibold">${title}</span></p>
            </div>
            <p class="font-semibold text-sm">${displayPopUpTitle(date, date2)}</p>
            <p>${body}</p>
        `;
    };

    const createEvent = (mass, adoration, startDate) => {
        const startDateISO = startDate.toISOString();
        if (adoration.number > 0 && mass.number > 0) {
            return {
                id: `event-${startDate.getDate()}`,
                startDate: startDateISO,
                title: `<div class="item-wrapper mass-wrapper"><span class="item-circle"></span>${mass.number} messes<br>adoration</div>`,
                tooltip: tooltipDisplay(mass, adoration, startDateISO),
                tooltip2: `${mass.hours}`,
                popUpTitle: `horaire des messes + adoration`,
                classes: ['event mass-event'],
                url: 'https://www.youtube.com/playlist?list=PLgzgcyL6piaOWIAEI1B2zeSIw6YDDUuk1',
                imageUrl: '',
                live:mass.live,
                type: 'mass'
            };
        } else if (mass.number > 0) {
            return {
                id: `event-${startDate.getDate()}`,
                startDate: startDateISO,
                title: `<div class="item-wrapper mass-wrapper"><span class="item-circle"></span>${mass.number} messes</div>`,
                tooltip: tooltipDisplay(mass, null, startDateISO),
                tooltip2: `${mass.hours}`,
                popUpTitle: `horaire des messes`,
                classes: ['event mass-event'],
                url: 'https://www.youtube.com/playlist?list=PLgzgcyL6piaOWIAEI1B2zeSIw6YDDUuk1',
                imageUrl: '',
                live:mass.live,
                type: 'mass'
            };
        } else if (adoration.number > 0) {
            return {
                id: `event-${startDate.getDate()}`,
                startDate: startDateISO,
                title: `<div class="item-wrapper mass-wrapper"><span class="item-circle"></span>adoration</div>`,
                tooltip: tooltipDisplay(null, adoration, startDateISO),
                tooltip2: `${adoration.hours}`,
                popUpTitle: `horaire de l'adoration`,
                classes: ['event mass-event'],
                imageUrl: '',
                live:false,
                type: 'adoration'
            };
        }
        return null;
    };

    return {
        generateEvents,
        generateMasses,
        filterMobileDates
    };
};

export default calendar;