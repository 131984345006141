// uncomment for development only, turns on HMR
// import "/src/sass/app.scss";


import $ from "jquery";
import 'lightbox2';
import initMenuFunctions from "./menus";
import initSliders from "./sliders";
import initUtilities from "./utils";
import initAnimations from "./animation";
import { createApp } from 'vue';
import Calendar from '../vue/calendar.vue';
import PrimeVue from 'primevue/config';

$(document).ready(function () {

  // initialize menu functionsù
  initMenuFunctions();

  // initialize slider carousels
  initSliders();

  // initialize the utility functions
  initUtilities();

  // initialize the animation functions
  initAnimations();

  // initialize the calendar
  createApp(Calendar).use(PrimeVue,{
    locale: {
      firstDayOfWeek: 0,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["D", "L", "M", "M", "J", "V", "S"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: "Aujourd'hui",
      clear: "Effacer",
    }
  }).mount('#calendar');


});
