import $ from "jquery";
import "slick-carousel";

window.jQuery = window.$ = $;
export const initSliders = () => {
  // HOMEPAGE TYPE - HERO SLIDER
  const slider = $(".slider");
  slider.slick({
    infinite: false,
    speed: 600,
    delay: 6000,
    slidesToShow: 1,
    fade: true,
    lazyLoad: "ondemand",
    autoplay: false,
    draggable: false,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          draggable: false,
          arrows: true,
          autoplay: false,
          swipe: false,
          swipeToSlide: false,
          touchMove: false,
        },
      },
    ],
  });


  if(window.innerWidth > 1024){
    $(".event-slider").slick({
      infinite: false,
      slidesToShow: 2.5,
      centerMode: true,
      initialSlide: 1,
      centerPadding: "0px",
    });

    $(".event-view-slider").slick({
      infinite: false,
      slidesToShow: 3,
    });
  } else {
    $(".mobile-event-slider").slick({
      infinite: false,
      slidesToShow: 2,
      responsive: [
      {
        breakpoint: 768,
        settings: {
        slidesToShow: 1,
        },
      }],
    });

    $(".mobile-view-slider").slick({
      infinite: false,
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: 768,
          settings: {
          infinite: false,
          slidesToShow: 1,
          },
        }
      ],
    });
  }

  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  });
  $('.slider-nav').slick({
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    asNavFor: '.slider-for',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
    ],

  });

  const slickSlider = $('.event-slider');
  const prevButton = $('.slick-prev'); // Select your previous button

  // Function to check if the previous button should be disabled
  function checkPrevButton(currentSlide) {
    if(currentSlide === 1 && $(window).width() >= 1024) {
      prevButton.prop('disabled', true);
      prevButton.addClass('slick-disabled')
    } else if($(window).width() >= 1024) {
      prevButton.prop('disabled', false);
      prevButton.removeClass('slick-disabled')
    }
  }

  // Initial check on document load
  if ($(window).width() >= 1024) {
    const initialSlide = slickSlider.slick('slickCurrentSlide');
    checkPrevButton(initialSlide);
  }
  // Check after slide change
  slickSlider.on('afterChange', function(event, slick, currentSlide){
    checkPrevButton(currentSlide);
  });
};
export default initSliders;
